/* eslint-disable consistent-return */
/* eslint-disable no-shadow */
/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable no-underscore-dangle */
/* eslint-disable array-callback-return */
/* eslint-disable react/jsx-no-constructed-context-values */
import React, { createContext, useState, useContext } from "react";
import { useApi } from "./axiosConfig";
import moment from "moment";

import { AuthContext } from "./auth";

export const CrudContext = createContext({});

function CrudProvider({ children }) {
  const { user: authUser, showAlert } = useContext(AuthContext);
  const { api } = useApi();

  const [customer, setCustomer] = useState();
  const [loading, setLoading] = useState(false);
  const [userSystem, setUserSystem] = useState([]);
  const [transacao, setTransacao] = useState();
  const [transacoes, setTransacoes] = useState([]);
  const [microTransacoes, setMicroTransacoes] = useState([]);
  const [listaChamados, setListaChamados] = useState([]);
  const [departamentos, setDepartamentos] = useState([]);
  const [rowGrid, setRowGrid] = useState([])
  const [arquivos, setArquivos] = useState([])
  const [mensalidades, setMensalidades] = useState([])
  const [showMicroTransactionDetails, setShowMicroTransactionDetails] = useState(false)
  const [typeNotificationTransaction, setTypeNotificationTransaction] = useState(0)
  const [adminDashboard, setAdminDashboard] = useState({});
  const [clientDashboard, setClientDashboard] = useState({});
  const [clientMailbox, setClientMailbox] = useState([]);
  const [clientMailboxDetails, setClientMailboxDetails] = useState({});
  const [showMailboxDetails, setShowMailboxDetails] = useState(false);
  const [clientPayments, setClientPayments] = useState([]);
  const [documentos, setDocumentos] = useState(undefined);
  const [financialCommitments, setFinancialCommitments] = useState({});
  const [financialCommitmentsSelected, setFinancialCommitmentsSelected] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [openNewAppointment, setOpenNewAppointment] = useState(false);
  const [servicosMunicipio, setServicosMunicipio] = useState([]);
  const [favoredByProvider, setFavoredByProvider] = useState([]);
  const [brazilStates, setBrazilStates] = useState([]);
  const [brazilStateTownships, setBrazilStateTownships] = useState([]);
  const [customerByCNPJ, setCustomerByCNPJ] = useState({});
  const [aliquotas, setAliquotas] = useState({});
  const [empresaServicos, setEmpresaServicos] = useState([]);
  const [impostosAcessorias, setImpostosAcessorias] = useState([]);
  const [obrigacoesProcessadas, setObrigacoesProcessadas] = useState([])
  const [obrigacoesManuais, setObrigacoesManuais] = useState([]);
  const [taxTypeManual, setTaxTypeManual] = useState([]);
  const [taxesUser, setTaxesUser] = useState([]);

  const configHeaderAuth = async () => {
    return {
      headers: {
        authorization: `token ${authUser.token}`
      }
    };
  }

  const urlBase = process.env.REACT_APP_LCR_API;

  const getAllCustomer = async () => {
    const config = await configHeaderAuth();

    await api
      .get(`${urlBase}/client/all`, config)
      .then((response) => {
        const _customer = [];
        if (response.data.length > 0) {
          response.data.map((item) => {
            _customer.push({
              id: item._id,
              item: item.item,
              diavencimento: item.diavencimento,
              apelido: item.apelido,
              cnpj: item.cnpj,
              empresa: item.empresa,
              unidade_matri_filialescp: item.unidade_matri_filialescp,
              inicionalcr: item.inicionalcr,
              datadaabertura: item.datadaabertura,
              datadabaixa: item.datadabaixa,
              motivodarescisaodocontratoc_lcr: item.motivodarescisaodocontratoc_lcr,
              status: item.status,
              ramodeatuacao: item.ramodeatuacao,
              atividade: item.atividade,
              indicacao: item.indicacao,
              destaque: item.destaque,
              faturamento: item.faturamento,
              confirmacaofaturamento: String(item.confirmacaofaturamento).toLocaleLowerCase() == 'x' ? 'Sim' : item.confirmacaofaturamento,
              contratoassit_financeiro: item.contratoassit_financeiro,
              contratosiscoserv: item.contratosiscoserv,
              sistemasafe_docretencoes: item.sistemasafe_docretencoes,
              socioresidentenoexterior: item.socioresidentenoexterior,
              empresacontabilresponsavel: item.empresacontabilresponsavel,
              controlecontabil1: String(item.controlecontabil1).toLocaleLowerCase() == 'x' ? 'Sim' : item.controlecontabil1,
              controlecontabil2: item.controlecontabil2,
              juntacom: item.juntacom,
              cartorio: item.cartorio,
              oab: item.oab,
              unidadecontabil: item.unidadecontabil,
              prioridadecontabilizacao: item.prioridadecontabilizacao,
              periodobalancetes: item.periodobalancetes,
              ibge_mensal: item.ibge_mensal,
              ibge_anual: item.ibge_anual,
              fazfiscal: item.fazfiscal,
              regimeapuracao_caixa_competencia: item.regimeapuracao_caixa_competencia,
              servicos: item.servicos,
              inscricaoestadual: item.inscricaoestadual,
              servicosprofissionais: item.servicosprofissionais,
              comercio: item.comercio,
              municipio: item.municipio,
              estado: item.estado,
              simplesnacional: item.simplesnacional,
              anexoiii: item.anexoiii,
              anexoiv: item.anexoiv,
              anexov: item.anexov,
              naturezajuridica: item.naturezajuridica,
              lucropresumido: String(item.lucropresumido).toLocaleLowerCase() == 'x' ? 'Sim' : item.lucropresumido,
              lucroreal: String(item.lucroreal).toLocaleLowerCase() == 'x' ? 'Sim' : item.lucroreal,
              imune_isenta: String(item.imune_isenta).toLocaleLowerCase() == 'x' ? 'Sim' : item.imune_isenta,
              nfeletronica: String(item.nfeletronica).toLocaleLowerCase() == 'x' ? 'Sim' : item.nfeletronica,
              baseirpj8: item.baseirpj8,
              baseirpj16: item.baseirpj16,
              baseirpj32: item.baseirpj32,
              piss_folha: item.piss_folha,
              controleirrfalugueis: item.controleirrfalugueis,
              apuracaoimpostosfederais: item.apuracaoimpostosfederais,
              clientescomemissaonfpelalcr: item.clientescomemissaonfpelalcr,
              modalidadedepro_labore: item.modalidadedepro_labore,
              dimob: item.dimob,
              dmed: item.dmed,
              taxaderesiduossolidos: item.taxaderesiduossolidos,
              decinscricaoestadual: item.decinscricaoestadual,
              dai_pmsp: item.dai_pmsp,
              controled_pessoal1: item.controled_pessoal1,
              controled_pessoal2: item.controled_pessoal2,
              pro: item.pro,
              func: item.func,
              auton: item.auton,
              dom: item.dom,
              dom_c_fgts: item.dom_c_fgts,
              rais: item.rais,
              fgtsfolhadomesticos: item.fgtsfolhadomesticos,
              sindicalpatronal: item.sindicalpatronal,
              sindicatoempregados: item.sindicatoempregados,
              regimepagtofolha_inclusiveprolabore: item.regimepagtofolha_inclusiveprolabore,
              sindicatopatronal: item.sindicatopatronal,
              capitalsocial: item.capitalsocial,
              grupodeempresas: item.grupodeempresas,
              porte: item.porte,
              lcr: item.lcr,
              lfol: item.lfol,
              lpat: item.lpat,
              lgrm: item.lgrm,
              lcr8: item.lcr8,
              consolidado: item.consolidado,
              recebeemail: item.recebeemail == "true" ? "Sim" : "Não",
              recebewhatapp: item.recebewhatapp == "true" ? "Sim" : "Não",
              executaecac: item.executaecac == "true" ? "Sim" : "Não",
              executaduc: item.executaduc == "true" ? "Sim" : "Não",
              executadas: item.executadas,
              datafilesituacaofiscal: item.datafilesituacaofiscal,
              filenamesituacaofiscal: item.filenamesituacaofiscal,
              filesituacaofiscal: item.filesituacaofiscal,
              situacaofiscalregular: item?.situacaofiscalregular
            });
          });
        }
        _customer.sort((a, b) => a.apelido.localeCompare(b.apelido));
        setCustomer(_customer);
      })
      .catch((error) => {
        console.info("error: ", error);
      });
  };

  const getAllUser = async (status) => {
    const user = [];
    try {
      const config = await configHeaderAuth();

      api
        .post(`${urlBase}/user/allDiferentRole`, { role: 'cliente' }, config)
        .then((response) => {
          response.data.map((item) => {
            if (status === 1 && item.status === true) {
              user.push({
                id: item._id,
                name: item.name,
                cpf: item.cpf && item.cpf.length > 0 ? item.cpf[0] : '',
                rg: item?.rg ? item.rg : '',
                descricaoCargo: item?.descricaoCargo ? item.descricaoCargo : '',
                cargo: item?.cargo ? item.cargo : '',
                nomeExibicao: item?.nomeExibicao ? item.nomeExibicao : '',
                email: item.email,
                adm: item.adm === true ? "Sim" : "Não",
                departamento: item.departamento,
                gestor: item.gestor,
                admissao: item.admissao,
                demissao: item.demissao,
                celular: item.celular,
                ramal: Number(item.ramal),
                funcao: item?.funcao,
                criadoPor: item.criadoPor,
                dataCricao: item.dataCricao,
                // ddr: item.ddr,
                // loginLcrConecta: item.loginLcrConecta,
                status: 'Ativo',
                avatarUrl: item.avatarUrl
              });
            }

            if (status === 0 && item.status === false) {
              user.push({
                id: item._id,
                name: item.name,
                cpf: item?.cpf ? item.cpf : '',
                rg: item?.rg ? item.rg : '',
                descricaoCargo: item?.descricaoCargo ? item.descricaoCargo : '',
                cargo: item?.cargo ? item.cargo : '',
                nomeExibicao: item?.nomeExibicao ? item.nomeExibicao : '',
                email: item.email,
                adm: item.adm === true ? "Sim" : "Não",
                departamento: item.departamento,
                gestor: item.gestor,
                admissao: item.admissao,
                demissao: item.demissao,
                celular: item.celular,
                ramal: Number(item.ramal),
                funcao: item?.funcao,
                criadoPor: item.criadoPor,
                dataCricao: item.dataCricao,
                // ddr: item.ddr,
                // loginLcrConecta: item.loginLcrConecta,
                status: 'Inativo',
                avatarUrl: item.avatarUrl
              });
            }
          });

          setUserSystem(user);
        })
        .catch((error) => {
          showAlert('error', 'Não foi possível processar sua solicitação');
        });
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
    }
  };

  const getAllExternalUser = async (status) => {
    const user = [];
    try {
      const config = await configHeaderAuth();

      api
        .post(`${urlBase}/user/allByRole`, { role: 'cliente' }, config)
        .then((response) => {
          response.data.map((item) => {
            const clienteWithId = Array.isArray(item.cpf)
              ? item.cpf.map((cpfItem, index) => ({
                ...cpfItem,
                id: cpfItem.cnpj
              }))
              : [];

            if (status === 1 && item.status === true) {
              user.push({
                id: item._id,
                name: item.name,
                email: item.email,
                cliente: clienteWithId
              });
            }

            if (status === 0 && item.status === false) {
              user.push({
                id: item._id,
                name: item.name,
                email: item.email,
                cliente: clienteWithId
              });
            }
          });

          setUserSystem(user);
        })
        .catch((error) => {
          showAlert('error', 'Não foi possível processar sua solicitação');
        });
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
    }
  };

  const alterUsersStatus = async (usersStatus, opcaoAtivo) => {
    const config = await configHeaderAuth();

    api
      .put(`${urlBase}/user/alterStatus`, { userStatus: usersStatus }, config)
      .then((response) => {
        getAllUser(opcaoAtivo);
      })
      .catch((error) => {
        console.info(
          `<-----------------${urlBase}/user/alterStatus ----------->`
        );
        console.info(`<----------Error: ${error}----------->`);

      });
  };

  const alterUsersStatusClienteExterno = async (usersStatus, opcaoAtivo) => {
    const config = await configHeaderAuth();

    api
      .put(`${urlBase}/user/alterStatus`, { userStatus: usersStatus }, config)
      .then((response) => {
        getAllExternalUser(opcaoAtivo)
      })
      .catch((error) => {
        console.info(
          `<-----------------${urlBase}/user/alterStatus ----------->`
        );
        console.info(`<----------Error: ${error}----------->`);

      });
  };

  const alterUserInterno = async (payload, opcaoAtivo) => {
    try {
      const config = await configHeaderAuth();

      const updateUsuario = await api.put(`${urlBase}/user/alter`, payload, config)
        .then(response => { return response.data })
        .catch(error => console.log('Error: alterUserInterno ', error))

      if (updateUsuario) {
        getAllUser(opcaoAtivo);
      }

    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
    }
  };

  const alterUserClienteExterno = async (payload, opcaoAtivo) => {
    try {
      const config = await configHeaderAuth();

      const updateUsuario = await api.put(`${urlBase}/user/alter`, payload, config)
        .then(response => { return response.data })
        .catch(error => console.log('Error: alterUserClienteExterno ', error))

      if (updateUsuario) {
        getAllExternalUser(1)
      }

    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
    }
  };

  const createUser = async (payload, opcaoAtivo) => {
    try {
      const config = await configHeaderAuth();

      const newPayload = payload;
      newPayload.password = payload.password ? payload.password : "1234";
      newPayload.confirmPassword = payload.confirmPassword ? payload.confirmPassword : "1234";

      await api.post(`${urlBase}/user/create`, newPayload, config).then(() => {
        if (newPayload.funcao === "cliente" || newPayload.funcao === "fornecedor") {
          getAllExternalUser(opcaoAtivo);
        } else {
          getAllUser(opcaoAtivo);
        }

      });
    } catch (error) {
      console.log('Erro no servidor');
      throw error;
    }
  };

  const executarEcac = async (payload) => {
    try {
      const date = new Date()
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/ecac/executarEcac`, {
        consultaEcac: JSON.parse(JSON.stringify(payload.cnpj)),
        idUsuario: payload.idUsuario,
      }, config);
      getGuiasByMonth(payload.idUsuario, date);
    } catch (error) {
      getGuiasByMonth(payload.idUsuario, date);
      console.log(
        "Ocorreu um erro ao finalizar a transação, id do usuário",
        payload.idUsuario
      );
      console.log("executarEcac error: ", error);
    }
  };

  const registrationDepartment = async (payload) => {
    try {
      const config = await configHeaderAuth();

      setLoading(true);
      const response = await api.post(
        `${urlBase}/departamento/create`,
        payload, config
      );
      if (response.status === 200) {
        return response.data;
      }
      return null;
    } catch (error) {
      console.log("registrationDepartment error: ", error);
      return null;
    } finally {
      setLoading(false);
    }
  };

  const getAllDepartamento = async () => {
    try {
      setLoading(true)

      const config = await configHeaderAuth();

      const _departamentos = await api.get(`${urlBase}/departamento/all`, config)
        .then(response => { return response.data })
        .catch(error => console.log(error))
        .finally(() => setLoading(false))

      if (Array.isArray(_departamentos)) {
        let list = []
        _departamentos.map(item => {
          list.push({
            value: item._id, label: item.nome, id: item._id, nome: item.nome
          })
        })

        setDepartamentos(list)
      }
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const registerTicket = async (payload, depto, funcao, user) => {
    try {
      setLoading(true)

      const config = await configHeaderAuth();

      const response = await api.post(`${urlBase}/ticket/create`, payload, config).then(
        (response) => {
          getAllbyDepto(depto, funcao, user)
          setLoading(false)
        })
      return response
    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const getAllbyDepto = async (depto, funcao, name) => {
    try {
      const config = await configHeaderAuth();

      const tikets = await api.post(`${urlBase}/ticket/getByDepto`,
        { departamento: depto, funcao: funcao, nome: name }, config).then((response) => { return response.data }).catch((error) => console.log('error'))

      if (Array.isArray(tikets)) {
        for (let index = 0; index < tikets.length; index++) {
          const tiketItens = tikets[index];
          tiketItens.id = tiketItens._id
        }
        setListaChamados(tikets)
      }

    } catch (error) {
      console.error(error);
      return null;
    }
  };

  const editarTicket = async (payload, depto, funcao, name) => {
    try {
      setLoading(true)

      const config = await configHeaderAuth();

      const updateTiket = await api.put(`${urlBase}/ticket/${payload._id}`, payload, config)
        .then(
          (response) => {
            getAllbyDepto(depto, funcao, name)
            setLoading(false)
          })
        .catch(error => console.log(error))
        .finally(() => setLoading(false))
    } catch (error) {
      console.log(error)
    }
  }

  const editDepartamento = async (id, payload) => {
    try {
      setLoading(true)

      const config = await configHeaderAuth();

      await api.put(`${urlBase}/departamento/edit/${id}`, payload, config)
        .then(response => {
          getAllDepartamento()
        })
        .catch(error => console.log("Error editDepartamento: ", error))
      setLoading(false)
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
    }
  }

  const deleteDepartamentoPorListaIds = async (listaIdDepartamentos) => {
    setLoading(true);

    const config = await configHeaderAuth();

    api.delete(`${urlBase}/departamento/deleteById`, {
      data: { departamentosDelete: JSON.parse(JSON.stringify(listaIdDepartamentos)) }, ...config
    })
      .then(() => {
        getAllDepartamento()
      })
      .catch((error) => {
        showAlert('error', 'Não foi possível processar sua solicitação');
      })
      .finally(() => setLoading(false));
  };

  const executarDas = async (payload) => {
    try {
      const config = await configHeaderAuth();

      const date = new Date()
      await api.post(`${urlBase}/das/executarDas`,
        { consultaDas: JSON.parse(JSON.stringify(payload.cnpj)), idUsuario: payload.idUsuario }, config)
      getDasByMonth(payload.idUsuario, date)
    } catch (error) {
      getDasByMonth(payload.idUsuario, date)
      console.log("Ocorreu um erro ao finalizar a transação, id do usuário", payload.idUsuario)
      console.log("executarDas error: ", error)
    }
  }

  const consultarMeusImpostos = async (payload) => {
    try {
      let impostos = []

      const config = await configHeaderAuth();

      api.post(`${urlBase}/transacoes/getMicroTransacaoByCNPJ`, payload, config)
        .then(response => {

          if (response.data.length === 0) {
            setRowGrid([])
            showAlert('warning', 'Nenhum registro encontrado!');
            return;
          }

          const meusImpostos = response.data.map((item, index) => {
            return { ...item, id: index, _id: undefined, pago: item.pago ? item.pago : false }
          })

          const impostosUnicos = [];

          meusImpostos.reverse().forEach(item => {
            const dataVencimento = item?.documentoarrecadacao?.datavencimento || undefined;
            if (dataVencimento) {
              const index = impostosUnicos.findIndex(registro => registro?.documentoarrecadacao?.datavencimento === dataVencimento);
              if (index === -1) {
                impostosUnicos.push(item);
              }
            }
          });

          setRowGrid(impostosUnicos)
          // response.data.map((item, index)=>{
          //   impostos.push({
          //     _id: index,
          //     cnpj: item.cnpj,
          //     datafinalizado: item.datafinalizado,
          //     datainicio: item.datainicio,
          //     linkarquivo: item.linkarquivo,
          //     nome: item.nome,
          //     numerorecibo: item.numerorecibo,
          //     sistema: item.sistema,
          //     status: item.status,
          //     pago: false
          //   })
          // })

          // setRowGrid(impostos)
          showAlert('success', 'Busca realizada com sucesso!');
          return;
        })
        .catch(error => { throw Error(error.message) })

    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
    }
  }

  const buscarMeusArquivos = async (cnpj) => {
    try {
      const payload = {
        cnpj: cnpj
      }

      const config = await configHeaderAuth();

      api.post(`${urlBase}/arquivos/allByCnpj`, payload, config)
        .then(response => {
          if (response.data) {
            const meusArquivos = response.data.map(item => {
              return { ...item, id: item._id, _id: undefined }
            })
            setArquivos(meusArquivos)
          }
        })
        .catch(error => { throw Error(error.message) })
    } catch (error) {
      console.log("Metodo: buscarMeusArquivos - ", error)
    }
  }

  const updateMicroTransacaoAndSaveFiles = async (payload) => {
    try {
      const cnpj = payload.cnpj

      const config = await configHeaderAuth();

      await api.post(`${urlBase}/arquivos/updateMicroTransacaoAndSaveFiles`, payload, config)
        .then(response => {
          if (response.data) {
            buscarMeusArquivos(cnpj)
          }
        })
        .catch(error => { throw Error(error.message) })
    } catch (error) {
      console.log("Metodo: buscarMeusArquivos - ", error)
    }
  }

  const insertNewFile = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/arquivos/insertNewFile`, payload, config)
        .then(response => {
          buscarMeusArquivos(payload.cnpj)
        })
        .catch(error => { throw Error(error.message) })

    } catch (error) {
      console.log("Metodo: buscarMeusArquivos - ", error)
    }
  }

  const insertMonthlyPayment = async (userId, files) => {
    try {
      const formData = new FormData();
      formData.append('pdfFile', files);

      const config = await configHeaderAuth();
      const headers = {
        ...config,
        'Content-Type': 'multipart/form-data',
      };

      await api.post(`${urlBase}/contabil/registraMensalidade/${userId}`, formData, headers);

      showAlert('success', 'O tempo de processamento pode levar até 2 horas');

    } catch (error) {
      console.log("Metodo: registrarMensalidade - ", error)
    }
  }

  const getMonthlypayments = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/contabil/retornaMensalidades`, payload, config).then(response => {
        if (response.data) {
          let mensalidades = response.data.map(item => {
            return { ...item, id: item._id }
          })
          setMensalidades(mensalidades)
        }
      });
    } catch (error) {
      console.log("Metodo: registrarMensalidade - ", error)
    }
  }

  const sendNotification = async (payload, tipo) => {
    try {
      const config = await configHeaderAuth();

      if (tipo === 1) {
        await api.post(`${urlBase}/contabil/notificaMensalidade`, payload, config).then(response => {
          if (response.data) {
            showAlert('success', 'E-mail enviado com sucesso');
          } else {
            showAlert('error', 'Não foi possível processar sua solicitação');
          }
        });
      }
      else if (tipo === 2) {
        await api.post(`${urlBase}/ecac/notificaGuias`, payload, config).then(response => {
          if (response.data) {
            showAlert('success', 'E-mail enviado com sucesso');
          } else {
            showAlert('error', 'Não foi possível processar sua solicitação');
          }
        });
      }
      else if (tipo === 3) {
        await api.post(`${urlBase}/das/notificaSN`, payload, config).then(response => {
          if (response.data) {
            showAlert('success', 'E-mail enviado com sucesso');
          } else {
            showAlert('error', 'Não foi possível processar sua solicitação');
          }
        });
      } else {
        showAlert('error', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      console.log("Metodo: sendNotification - ", error)
    }
  }

  const sendNotificationWhatsApp = async (payload, tipo) => {
    try {
      const config = await configHeaderAuth();

      if (tipo === 1) {
        await api.post(`${urlBase}/contabil/notificaMensalidadeWhatsApp`, payload, config).then(response => {
          if (response.data) {
            showAlert('success', 'Mensagem enviada com sucesso');
          } else {
            showAlert('error', 'Não foi possível processar sua solicitação');
          }
        });
      }
      else if (tipo === 2) {
        await api.post(`${urlBase}/ecac/notificaGuiasWhatsApp`, payload, config).then(response => {
          if (response.data) {
            showAlert('success', 'Mensagem enviada com sucesso');
          } else {
            showAlert('error', 'Não foi possível processar sua solicitação');
          }
        });
      }
      else if (tipo === 3) {
        await api.post(`${urlBase}/das/notificaSNWhatsApp`, payload, config).then(response => {
          if (response.data) {
            showAlert('success', 'Mensagem enviada com sucesso');
          } else {
            showAlert('error', 'Não foi possível processar sua solicitação');
          }
        });
      } else {
        showAlert('error', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      console.log("Metodo: sendNotificationWhatsApp - ", error)
    }
  }

  const getGuiasByMonth = async (idUsuario, date) => {
    setTransacao([])
    if (!date) {
      date = moment().format('YYYY-MM-DD');
    }
    const body = {
      date: date
    };

    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/ecac/getByMonth/${idUsuario}`, body, config)
        .then((response) => {
          setTransacoes(response.data);
          if (response.data.length > 0) {
            showAlert('success', 'Pesquisa realizada com sucesso!');
          } else {
            showAlert('warning', 'A pesquisa não retornou resultados.');
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const getDasByMonth = async (idUsuario, date) => {
    setTransacao([])
    if (!date) {
      date = moment().format('YYYY-MM-DD');
    }

    const body = {
      date: date
    };
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/das/getByMonth/${idUsuario}`, body, config)
        .then((response) => {
          setTransacoes(response.data);
          if (response.data.length > 0) {
            showAlert('success', 'Pesquisa realizada com sucesso!');
          } else {
            showAlert('warning', 'A pesquisa não retornou resultados.');
          }
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const getDasMicroTransacoes = async (id) => {
    setMicroTransacoes([]);
    try {
      const config = await configHeaderAuth();

      await api.get(`${urlBase}/das/getMicroTransactionById/${id}`, config)
        .then((response) => {
          const data = response.data.map((item, index) => {
            return {
              ...item,
              _id: item.cnpj,
              idTransacao: id,
              indice: index
            }
          })
          setMicroTransacoes(data)

        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const enviaWppMensalidade = async (ids) => {
    const body = {
      ids: ids
    };
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/contabil/notificaMensalidadeWhatsApp`, body, config)
        .then((response) => {
          setTransacoes(response.data)
          showAlert('success', 'Notificação enviada');
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const enviaEmailMensalidade = async (ids) => {

    const body = {
      ids: ids
    };
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/contabil/notificaMensalidade`, body, config)
        .then((response) => {
          setTransacoes(response.data)
          showAlert('success', 'Notificação enviada');
        })
        .catch((error) => {
          console.log(error)
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const getAdminDashboardData = async () => {
    const config = await configHeaderAuth();

    const { data, status } = await api.get(`${urlBase}/client/getAdminDashboard`, config);

    if (data && status === 200) {
      setAdminDashboard(data);
    } else {
      setAdminDashboard({});
    }
  };

  const getClientDashboardData = async (cnpj) => {
    const config = await configHeaderAuth();

    const { data, status } = await api.get(`${urlBase}/client/getDashboardData?cnpj=${cnpj}`, config);

    if (data && status === 200) {
      setClientDashboard(data);
    } else {
      setClientDashboard({});
    }
  };

  const insertFileAWSS3Bucket = async (payload) => {
    try {
      const formData = new FormData();
      formData.append('file', payload);

      const config = await configHeaderAuth();
      const headers = {
        ...config,
        'Content-Type': 'multipart/form-data',
      };

      const insertedFile = await api.post(`${urlBase}/arquivos/insertNewFileS3`, formData, headers);

      return insertedFile.data;
    } catch (error) {
      console.log("Metodo: insertFileAWSS3Bucket - ", error);
      throw error;
    }
  }

  const getClientMailbox = async (cnpj, tipo) => {
    setClientMailbox([]);
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/client/getMailboxClient?contribuinte=${cnpj}&tipo=${tipo}`, config);

      if (status === 200 && data) {
        setClientMailbox(data);
      }
      else {
        showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.');
      }
    } catch (error) {
      console.error(error);
      showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.');
    }
  };

  const getClientMailboxDetails = async (cnpj, tipo, isn) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/client/getMailboxClientDetails?contribuinte=${cnpj}&tipo=${tipo}&isn=${isn}`, config);

      if (data && data.dados && data.dados.conteudo[0] && status === 200) {
        setClientMailboxDetails(data.dados.conteudo[0]);
      } else {
        showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.')
        setClientMailboxDetails({});
      }
    } catch (error) {
      console.error(error);
      showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.')
      setClientMailboxDetails({});
    }
  };

  const getRaisePayments = async (cnpj, tipo, dataInicio, dataFim) => {
    try {
      const config = await configHeaderAuth();

      const dateFrom = dataInicio.toISOString().split('T')[0];
      const dateUntil = dataFim.toISOString().split('T')[0];

      const { data, status } = await api.get(`${urlBase}/client/getRaisePayments?contribuinte=${cnpj}&tipo=${tipo}&dataInicio=${dateFrom}&dataFim=${dateUntil}`, config);

      if (data && status === 200) {
        if (data.dados && data.dados.length > 0) {
          setClientPayments(data.dados);
          showAlert('success', 'Pesquisa concluída com sucesso!');
        } else {
          showAlert('warning', 'Não foram localizados registros para a empresa e período selecionados.');
        }
      } else {
        showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.')
        setClientPayments([]);
      }
    } catch (error) {
      console.error(error);
      showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.')
      setClientPayments([]);
    }
  };

  const getRaiseReceipt = async (cnpj, tipo, numeroDocumento) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/client/getRaiseReceipt?contribuinte=${cnpj}&tipo=${tipo}&numeroDocumento=${numeroDocumento}`, config);

      if (status === 200 && data && data.dados && data.dados.pdf) {
        return data.dados.pdf
      } else {
        showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.')
        return undefined;
      }
    } catch (error) {
      console.error(error);
      showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.')
      return undefined;
    }
  };

  const getDocumentos = async (cnpj, dataInicio, dataFim) => {
    setClientMailbox([]);

    const dateFrom = dataInicio.toISOString().split('T')[0];
    const dateUntil = dataFim.toISOString().split('T')[0];

    try {
      const config = await configHeaderAuth();

      const response = await api.get(`${urlBase}/arquivos/getAcessorias?cnpj=${cnpj}&dataInicio=${dateFrom}&dataFim=${dateUntil}`, config);
      let _documentos = []
      if (response.data.Entregas?.length > 0) {
        response.data.Entregas.forEach((item) => {
          _documentos.push({
            id: item.Nome + ' - ' + (item.EntCompetencia ? moment(item.EntCompetencia).format('MM/YYYY') : ''),
            nome: item.Nome,
            EntCompetencia: item.EntCompetencia ? moment(item.EntCompetencia).format('MM/YYYY') : '',
            EntDtPrazo: item.EntDtAtraso ? moment(item.EntDtAtraso).format('DD/MM/YYYY') : '',
            Anexos: item.Anexos[0]
          });
        });
      }
      _documentos.sort((a, b) => a.id.localeCompare(b.id));

      const filtrado = _documentos.filter(item => item.Status !== "Dispensada" && item.Anexos);

      const idsUnicos = {};

      filtrado.forEach(item => {
        if (!idsUnicos[item.id]) {
          idsUnicos[item.id] = item;
        }
      });

      const resultado = Object.values(idsUnicos);

      setDocumentos(resultado);
    } catch (error) {
      console.error(error);
      showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.');
    }
  };

  const getFinancialCommitments = async (cnpj, selectedYear, selectedMonth) => {
    const config = await configHeaderAuth();

    const { data, status } = await api.get(`${urlBase}/client/getFinancialCommitments?cnpj=${cnpj}&year=${selectedYear}&month=${selectedMonth}`, config);

    if (data && status === 200) {
      setFinancialCommitments(data);
    } else {
      setFinancialCommitments({});
    }
  };

  const getAppointments = async (payload) => {
    try {
      const config = await configHeaderAuth();

      const { cnpj, year, month } = payload;

      const { data, status } = await api.get(`${urlBase}/appointment/getAppointments?cnpj=${cnpj}&year=${year}&month=${month}`, config);

      if (data && status === 200) {
        if (data.length > 0) {
          let appointmentsFull = data.map(item => {
            return { ...item, id: item._id }
          });

          setAppointments(appointmentsFull);
        } else {
          showAlert('warning', 'Não há resultados para o período selecionado');
          setAppointments([]);
        }


      } else {
        showAlert('error', 'Não foi possível processar sua solicitação');
        setAppointments([]);
      }
    } catch (error) {
      console.log("Metodo: getAppointments", error)
      throw error
    }
  }

  const insertNewAppointment = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/appointment/insertNewAppointment`, payload, config)
        .then(() => {
          showAlert('success', 'Agenda cadastrada com sucesso');
        })
        .catch((error) => {
          console.log(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const editAppointment = async (id, payload) => {
    try {
      const config = await configHeaderAuth();

      await api.put(`${urlBase}/appointment/${id}`, payload, config)
        .then(() => {
          showAlert('success', 'Agenda alterada com sucesso');
        })
        .catch((error) => {
          console.log(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const editAppointmentStatus = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.put(`${urlBase}/appointment/editAppointmentStatus`, payload, config)
        .then(() => {
          showAlert('success', 'Agenda excluída com sucesso');
        })
        .catch((error) => {
          console.log(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const getCNPJInfoByFocusNFE = async (cnpj) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/client/getCNPJInfoByFocusNFE?cnpj=${cnpj}`, config);

      if (data && status === 200) {
        return data;
      } else {
        showAlert('error', 'Não foi possível processar sua solicitação');
        throw new Error('Metodo: getCNPJInfoByFocusNFE. Status da API', status);
      }
    } catch (error) {
      console.log("Metodo: getCNPJInfoByFocusNFE", error);
      throw error;
    }
  }

  const getServicosMunicipioByFocusNFE = async (codigoMunicipio) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/contabil/getServicosMunicipioByFocusNFE?codigoMunicipio=${codigoMunicipio}`, config);

      if (data && status === 200) {
        setServicosMunicipio(data);
      } else {
        showAlert('error', 'Não foi possível processar sua solicitação');
        setServicosMunicipio([]);
      }
    } catch (error) {
      setServicosMunicipio([]);
      console.log("Metodo: getServicosMunicipioByFocusNFE", error);
      throw error;
    }
  }

  const getFavoredByProvider = async (cnpj) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/client/getFavoredByProvider?cnpj=${cnpj}`, config);

      if (data && status === 200) {
        data.sort((a, b) => a.favRazaoSocial.localeCompare(b.favRazaoSocial));

        const dataFull = data.map((item, index) => (
          {
            ...item,
            id: item?._id ? item._id : index,
          }))

        setFavoredByProvider(dataFull);
      } else {
        setFavoredByProvider([]);
        showAlert('warning', 'Ocorreu um erro ao buscar os favorecidos');
      }
    } catch (error) {
      setFavoredByProvider([]);
      showAlert('warning', 'Ocorreu um erro ao buscar os favorecidos');
      throw error;
    }
  }

  const getBrazilStates = async () => {
    try {
      const { data, status } = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados`);

      if (data && status === 200) {
        const sortedData = data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);

        setBrazilStates(sortedData);
      } else {
        showAlert('warning', 'Ocorreu um erro ao listar os estados');
        setBrazilStates([]);
      }
    } catch (error) {
      setBrazilStates([]);
      showAlert('warning', 'Ocorreu um erro ao listar os estados');
      throw error;
    }
  }

  const getBrazilStateTownships = async (uf) => {
    try {
      const { data, status } = await api.get(`https://servicodados.ibge.gov.br/api/v1/localidades/estados/${uf}/municipios`);

      if (data && status === 200) {
        const sortedData = data.sort((a, b) => (a.nome > b.nome) ? 1 : -1);

        setBrazilStateTownships(sortedData);
      } else {
        showAlert('warning', 'Ocorreu um erro ao listar os municípios');
        setBrazilStateTownships([]);
      }
    } catch (error) {
      setBrazilStateTownships([]);
      console.log("Metodo: getBrazilStateTownships", error);
      throw error;
    }
  }

  const getCustomerByCNPJ = async (cnpj) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/client/getClientbyCNPJ?cnpj=${cnpj}`, config);

      if (data && status === 200) {
        setCustomerByCNPJ(data);
      } else {
        setCustomerByCNPJ(null);
        showAlert('warning', 'Ocorreu um erro ao buscar as informações do CNPJ');
      }
    } catch (error) {
      setCustomerByCNPJ(null);
      showAlert('warning', 'Ocorreu um erro ao buscar as informações do CNPJ');
      console.log("Metodo: getCustomerByCNPJ", error);
      throw error;
    }
  }

  const createFavoredByProvider = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/client/createFavoredByProvider`, payload, config)
        .then(() => {
          showAlert('success', 'Favorecido salvo com sucesso!');
          getFavoredByProvider(payload.prestCNPJ);
        })
        .catch((error) => {
          console.log(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
    }
  }

  const emitirNFSeByFocusNFE = async (payload) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.post(`${urlBase}/contabil/emitirNFSeByFocusNFE`, payload, config);

      if (data && (status === 200 || status === 202)) {
        const { success, message } = data;

        if (success) {
          showAlert('success', message);
        } else {
          showAlert('warning', message);
        }
      } else {
        showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.');
      }
    } catch (error) {
      showAlert('error', 'Ocorreu um erro ao processar a solicitação, tente novamente mais tarde. Caso o erro persista, entre em contato com o administrador do sistema.');
    }
  }


  const getAliquotas = async () => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/arquivos/handleGetAliquotas`, config);

      if (data && status === 200) {
        setAliquotas(data.map((item) => ({
          ...item,
          id: item._id ? item._id : '',
        })));
      } else {
        setAliquotas(null);
        showAlert('warning', 'Ocorreu um erro ao buscar aliquotas');
      }
    } catch (error) {
      setAliquotas(null);
      showAlert('warning', 'Ocorreu um erro ao buscar aliquotas');
      console.log("Metodo: getAliquotas", error);
      throw error;
    }
  }

  const handleUpdateAliquotas = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const config = await configHeaderAuth();
      const headers = {
        ...config,
        'Content-Type': 'multipart/form-data',
      };

      const { status } = await api.post(`${urlBase}/arquivos/updateAliquotas`, formData, headers);

      if (status === 200) {
        showAlert('success', 'Atualizado');
      } else {
        showAlert('error', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      setAliquotas(null);
      showAlert('error', 'Não foi possível processar sua solicitação');
      console.log("Metodo: handleUpdateAliquotas", error);
      throw error;
    }
  }


  const getEmpresaServicos = async () => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/arquivos/handleGetEmpresaServicos`, config);

      if (data && status === 200) {
        setEmpresaServicos(data.map((item) => ({
          ...item,
          id: item._id ? item._id : '',
        })));
      } else {
        setEmpresaServicos([]);
        showAlert('error', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      setEmpresaServicos([]);
      showAlert('error', 'Não foi possível processar sua solicitação');
      console.log("Metodo: getEmpresaServicos", error);
      throw error;
    }
  }

  const handleGetAllTaxesAcessorias = async (competencia) => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/arquivos/handleGetAllTaxesAcessorias${competencia}`, config);

      if (data && status === 200) {
        setImpostosAcessorias(data.map((item) => ({
          ...item,
          id: item._id ? item._id : '',
        })));
      } else {
        setImpostosAcessorias([]);
        showAlert('warning', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      setImpostosAcessorias([]);
      showAlert('warning', 'Não foi possível processar sua solicitação');
      console.log("Metodo: handleGetAllTaxesAcessorias", error);
      throw error;
    }
  }

  const handleUpdateEmpresaServicos = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file);
      const config = await configHeaderAuth();
      const headers = {
        ...config,
        'Content-Type': 'multipart/form-data',
      };

      const { status } = await api.post(`${urlBase}/arquivos/updateEmpresaServicos`, formData, headers);

      if (status === 200) {
        showAlert('success', 'Atualizado');
      } else {
        showAlert('error', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      setEmpresaServicos([]);
      showAlert('error', 'Não foi possível processar sua solicitação');
      console.log("Metodo: handleUpdateEmpresaServicos", error);
      throw error;
    }
  }

  const getAliquotasByUser = async (cnpj) => {
    try {
      const config = await configHeaderAuth();

      const { data } = await api.get(`${urlBase}/user/getServiceByUserId?cnpj=${cnpj}`, config);

      const services = data.map((item) => ({
        id: item.codigo_servico,
        label: item.descricao,
        aliquota: item.aliquota
      }));

      return services;
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
      console.log("Metodo: getEmpresaServicos", error);
      throw error;
    }
  }

  // const getNFSeByFocusNFE = async (cnpj, limit) => {
  //   const config = await configHeaderAuth();

  //   const { data, status } = await api.get(`${urlBase}/contabil/getNFSeByFocusNFE?cnpj=${cnpj}&limit=${limit}`, config);

  //   if (data && status === 200) {
  //     const nfse = data.map(item => {
  //       return { ...item, id: item._id }
  //     })
  //     return nfse;
  //   } else {
  //     return [];
  //   }
  // };

  const getNFSeByFocusNFE = async (cnpj, limit) => {
    const config = await configHeaderAuth();

    const { data, status } = await api.get(`${urlBase}/contabil/getNFSeByFocusNFE?cnpj=${cnpj}&limit=${limit}`, config);

    if (data && status === 200) {
      const nfse = data.map(item => {
        return { ...item, id: item._id }
      })
      return nfse;
    } else {
      return [];
    }
  };

  const deleteFavoredByProvider = async (cnpj, id) => {
    setLoading(true);

    const config = await configHeaderAuth();

    api.delete(`${urlBase}/client/deleteFavoredbyProviderId`, { data: { id }, ...config })
      .then(() => {
        getFavoredByProvider(cnpj);
        showAlert('success', 'Favorecido removido com sucesso!');
      })
      .catch((error) => {
        console.info("error: ", error);
        showAlert('error', 'Não foi possível processar sua solicitação')
      })
      .finally(() => setLoading(false));
  };

  const processTaxAllCustomersByAcessorias = async () => {
    try {
      const config = await configHeaderAuth();

      api.post(`${urlBase}/arquivos/processTaxAllCustomersByAcessorias`, {}, config);

      showAlert('success', 'A requisição foi registrada. O tempo de processamento leva em média 2 horas. Aguarde esse tempo para verificar o resultado.');
    } catch (error) {
      showAlert('error', 'Ocorreu um erro ao processar a requisição. Entre em contato com o administrador do sistema.');
    }
  }

  const getTaxFileProcessInfo = async (year, month) => {
    try {
      const config = await configHeaderAuth();

      await api.get(`${urlBase}/arquivos/getTaxFileProcessInfo?ano=${year}&mes=${month}`, config)
        .then(response => {
          if (response.data) {
            let retorno = response.data.map(item => {
              return { ...item, id: item._id }
            }).sort((a, b) => new Date(b.dataInclusao) - new Date(a.dataInclusao));
            setObrigacoesProcessadas(retorno);
          } else {
            setObrigacoesProcessadas([]);
          }
        })
        .catch((error) => {
          console.error("Metodo: getTaxFileProcessInfo - ", error);
          setObrigacoesProcessadas([]);
          showAlert('error', 'Não foi possível processar sua solicitação');
        });
    } catch (error) {
      console.error("Metodo: getTaxFileProcessInfo - ", error);
      setObrigacoesProcessadas([]);
      showAlert('error', 'Não foi possível processar sua solicitação');
    }
  }

  const processTaxFileAllCustomers = async (userId, zipFile) => {
    try {
      const formData = new FormData();
      formData.append('file', zipFile);

      const config = await configHeaderAuth();
      const headers = {
        ...config,
        'Content-Type': 'multipart/form-data',
      };

      await api.post(`${urlBase}/arquivos/processTaxFilesPackage/${userId}`, formData, headers);

      showAlert('success', 'O tempo de processamento pode levar até 2 horas. Acompanhe o processamento, atualizando o filtro para a mês atual.');

    } catch (error) {
      console.error("Metodo: processTaxFileAllCustomers - ", error)
    }
  }

  const getNoMapTaxFileInfo = async (selectedYear, selectedMonth) => {
    try {
      const mesCompetencia = `?mesCompetencia=${selectedMonth}/${selectedYear}`
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/arquivos/getNoMapTaxFileInfo${mesCompetencia}`, config);

      data.sort((a, b) => b.dataUltimaAlteracao.localeCompare(a.dataUltimaAlteracao));

      if (data && status === 200) {
        setObrigacoesManuais(data.map((item) => ({
          ...item,
          id: item._id ? item._id : '',
        })));
      } else {
        setObrigacoesManuais([]);
        showAlert('warning', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      setObrigacoesManuais([]);
      showAlert('warning', 'Não foi possível processar sua solicitação');
      console.error("Metodo: getNoMapTaxFileInfo", error);
      throw error;
    }
  }

  const createNoMapTaxFileInfo = async (payload) => {
    try {
      const { cnpj, mesCompetencia, valor, dataVencimento, nomeImposto, infosAdicionais, taxFile, idUser } = payload;
      const formData = new FormData();

      if (taxFile) { formData.append('file', taxFile) }
      if (idUser) { formData.append('idUser', idUser) }
      if (cnpj) { formData.append('cnpj', cnpj) }
      if (mesCompetencia) { formData.append('mesCompetencia', mesCompetencia) }
      if (valor) { formData.append('valor', valor) }
      if (dataVencimento) { formData.append('dataVencimento', dataVencimento) }
      if (nomeImposto) { formData.append('nomeImposto', nomeImposto) }
      if (infosAdicionais) { formData.append('infosAdicionais', infosAdicionais) }

      const config = await configHeaderAuth();
      const headers = {
        ...config,
        'Content-Type': 'multipart/form-data',
      };

      await api.post(`${urlBase}/arquivos/createNoMapTaxFileInfo`, formData, headers);

      showAlert('success', 'Registro inserido com sucesso!');
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação');
      console.error("Metodo: createNoMapTaxFileInfo - ", error);
    }
  }

  const updateNoMapTaxFileInfo = async (payload) => {
    try {
      const { id, cnpj, mesCompetencia, valor, dataVencimento, nomeImposto, infosAdicionais, taxFile, idUser } = payload;
      const formData = new FormData();

      if (id) { formData.append('id', id) }
      if (taxFile) { formData.append('file', taxFile) }
      if (idUser) { formData.append('idUser', idUser) }
      if (cnpj) { formData.append('cnpj', cnpj) }
      if (mesCompetencia) { formData.append('mesCompetencia', mesCompetencia) }
      if (valor) { formData.append('valor', valor) }
      if (dataVencimento) { formData.append('dataVencimento', dataVencimento) }
      if (nomeImposto) { formData.append('nomeImposto', nomeImposto) }
      if (infosAdicionais) { formData.append('infosAdicionais', infosAdicionais) }

      const config = await configHeaderAuth();
      const headers = {
        ...config,
        'Content-Type': 'multipart/form-data',
      };

      await api.post(`${urlBase}/arquivos/updateNoMapTaxFileInfo`, formData, headers);

      showAlert('success', 'Registro alterado com sucesso!');
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
      console.error("Método: updateNoMapTaxFileInfo - ", error);
    }
  }

  const updateStatusNoMapTaxFileInfo = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/arquivos/updateStatusNoMapTaxFileInfo`, payload, config)
        .then(() => {
          showAlert('success', 'Registro excluído com sucesso!');
        })
        .catch((error) => {
          console.error(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
      console.error("Método: updateStatusNoMapTaxFileInfo - ", error);
    }
  }

  const getTaxType = async () => {
    try {
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/arquivos/getTaxType`, config);

      if (data && status === 200) {
        setTaxTypeManual(data.map((item) => ({
          ...item,
          id: item._id ? item._id : '',
        })).sort((a, b) => a.nome.localeCompare(b.nome)));
      } else {
        setTaxTypeManual([]);
        showAlert('warning', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      setTaxTypeManual([]);
      showAlert('warning', 'Não foi possível processar sua solicitação');
      console.error("Metodo: getTaxType", error);
      throw error;
    }
  }

  const createTaxType = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/arquivos/createTaxType`, payload, config)
        .then(() => {
          showAlert('success', 'Registro criado com sucesso!');
        })
        .catch((error) => {
          console.error(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
      console.error("Método: createTaxType - ", error);
    }
  }

  const alterTaxType = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/arquivos/alterTaxType`, payload, config)
        .then(() => {
          showAlert('success', 'Registro alterado com sucesso!');
        })
        .catch((error) => {
          console.error(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
      console.error("Método: alterTaxType - ", error);
    }
  }

  const updateStatusTaxType = async (payload) => {
    try {
      const config = await configHeaderAuth();

      await api.post(`${urlBase}/arquivos/updateStatusTaxType`, payload, config)
        .then(() => {
          showAlert('success', 'Registro excluído com sucesso!');
        })
        .catch((error) => {
          console.error(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
      console.error("Método: updateStatusTaxType - ", error);
    }
  }

  const notifyTaxesConecta = async (ids) => {
    try {
      const config = await configHeaderAuth();

      const payload = {
        ids: ids
      }

      await api.post(`${urlBase}/arquivos/notifyTaxesConecta`, payload, config)
        .then(() => {
          showAlert('success', 'Envio de e-mail processado com sucesso!');
        })
        .catch((error) => {
          console.error(error);
          showAlert('error', 'Não foi possível processar sua solicitação');
        })
    } catch (error) {
      showAlert('error', 'Não foi possível processar sua solicitação')
      console.error("Método: notifyTaxesConecta - ", error);
    }
  }

  const getTaxesByUser = async (cnpj, selectedYear, selectedMonth) => {
    try {
      const mesCompetencia = `mesCompetencia=${selectedMonth}/${selectedYear}`
      const config = await configHeaderAuth();

      const { data, status } = await api.get(`${urlBase}/arquivos/getTaxesByUser?cnpj=${cnpj}&${mesCompetencia}`, config);

      data.sort((a, b) => a.nomeImposto.localeCompare(b.nomeImposto));

      if (data && status === 200) {
        setTaxesUser(data.map((item) => ({
          ...item,
          id: item._id ? item._id : '',
        })));
      } else {
        setTaxesUser([]);
        showAlert('warning', 'Não foi possível processar sua solicitação');
      }
    } catch (error) {
      setTaxesUser([]);
      showAlert('warning', 'Não foi possível processar sua solicitação');
      console.error("Metodo: getTaxesByUser", error);
      throw error;
    }
  }

  return (
    <CrudContext.Provider
      value={{
        registerTicket,
        getAllDepartamento,
        registrationDepartment,
        loading,
        customer,
        getAllCustomer,
        getAllUser,
        userSystem,
        alterUsersStatus,
        createUser,
        executarEcac,
        transacao,
        transacoes,
        setMicroTransacoes,
        microTransacoes,
        setLoading,
        listaChamados,
        setDepartamentos,
        departamentos,
        editarTicket,
        getAllbyDepto,
        editDepartamento,
        deleteDepartamentoPorListaIds,
        executarDas,
        getAllExternalUser,
        alterUserClienteExterno,
        alterUsersStatusClienteExterno,
        consultarMeusImpostos,
        rowGrid,
        buscarMeusArquivos, arquivos,
        updateMicroTransacaoAndSaveFiles,
        insertNewFile,
        alterUserInterno,
        insertMonthlyPayment,
        getMonthlypayments,
        mensalidades,
        sendNotification,
        sendNotificationWhatsApp,
        getGuiasByMonth,
        getDasByMonth,
        getDasMicroTransacoes,
        setShowMicroTransactionDetails,
        showMicroTransactionDetails,
        enviaEmailMensalidade,
        enviaWppMensalidade,
        typeNotificationTransaction,
        setTypeNotificationTransaction,
        getAdminDashboardData,
        adminDashboard,
        getClientDashboardData,
        clientDashboard,
        insertFileAWSS3Bucket,
        getClientMailbox,
        clientMailbox,
        setClientMailbox,
        showMailboxDetails,
        setShowMailboxDetails,
        getClientMailboxDetails,
        clientMailboxDetails,
        setClientMailboxDetails,
        getRaisePayments,
        clientPayments,
        setClientPayments,
        getRaiseReceipt,
        getDocumentos,
        documentos,
        setDocumentos,
        getFinancialCommitments,
        financialCommitments,
        financialCommitmentsSelected,
        setFinancialCommitmentsSelected,
        getAppointments,
        insertNewAppointment,
        editAppointment,
        editAppointmentStatus,
        appointments,
        openNewAppointment,
        setOpenNewAppointment,
        getCNPJInfoByFocusNFE,
        getServicosMunicipioByFocusNFE,
        servicosMunicipio,
        getFavoredByProvider,
        favoredByProvider,
        getBrazilStates,
        brazilStates,
        getBrazilStateTownships,
        brazilStateTownships,
        getCustomerByCNPJ,
        customerByCNPJ,
        createFavoredByProvider,
        emitirNFSeByFocusNFE,
        aliquotas,
        getAliquotas,
        handleUpdateAliquotas,
        empresaServicos,
        getEmpresaServicos,
        handleUpdateEmpresaServicos,
        getAliquotasByUser,
        getNFSeByFocusNFE,
        deleteFavoredByProvider,
        handleGetAllTaxesAcessorias,
        impostosAcessorias,
        processTaxAllCustomersByAcessorias,
        getTaxFileProcessInfo,
        obrigacoesProcessadas,
        setObrigacoesProcessadas,
        processTaxFileAllCustomers,
        getNoMapTaxFileInfo,
        obrigacoesManuais,
        createNoMapTaxFileInfo,
        updateNoMapTaxFileInfo,
        updateStatusNoMapTaxFileInfo,
        getTaxType,
        taxTypeManual,
        alterTaxType,
        createTaxType,
        updateStatusTaxType,
        notifyTaxesConecta,
        getTaxesByUser,
        taxesUser
      }}
    >
      {children}
    </CrudContext.Provider>
  );
}

export default CrudProvider;
