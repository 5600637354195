import { lazy } from 'react';

import Loadable from 'ui-component/Loadable';
import MinimalLayout from 'layout/MinimalLayout';

const DownloadMyTax = Loadable(lazy(() => import('views/pages/DownloadMyTax')));

const DownloadRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      path: '/downloadMyTax/:arquivoId',
      element: <DownloadMyTax />
    }
  ]
};

export default DownloadRoutes;
